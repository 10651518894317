.ui.grid {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.ui.grid+.grid {
  margin-top: 0rem !important;
}

.ui.grid >.ui.grid+.grid {
  margin-top: 1rem !important;
}

body { 
  //background-color: #FBFBFB;
  //background-color: #ededed;
}

#deposit-form .deposit-main .ui.card {
    width: unset !important;
}

#deposit-form .hide-inputs {
    width:100%;
    visibility:hidden;
    overflow: hidden;
    height:0px;
}
.importFromSources .ui.label {
  width:120px;
  text-align: center;
}

.drukm-logo {
    max-height: 50px;
    width: auto;
}
.outer-navbar {
  //temp only
  background-image: unset;
  background-color: #006085;
}
.ui.accordion.invenio-accordion-field .title,
.ui.primary.button {
  background-color: #006085 !important;
}
/*.outer-navbar {
    background-image: unset;
    border-color: unset;
}*/
/*.frontpage .outer-navbar {
    background-color: #006085;
    backdrop-filter: unset;
}*//*
.outer-navbar,
.frontpage .outer-navbar.scrolled {
    background-color: rgba(251,251,251,0.8);
    backdrop-filter: blur(12px);
}*/